import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const data = [
  {
    name: 'jan',
    signups: 4000,
    tokens: 2400,
  },
  {
    name: 'feb',
    signups: 3000,
    tokens: 1398,
  },
  {
    name: 'mar',
    signups: 2000,
    tokens: 9800,
  },
  {
    name: 'apr',
    signups: 2780,
    tokens: 3908,
  },
  {
    name: 'may',
    signups: 1890,
    tokens: 4800,
  },
  {
    name: 'jun',
    signups: 2390,
    tokens: 3800,
  },
  {
    name: 'jul',
    signups: 3490,
    tokens: 4300,
  },
  {
    name: 'aug',
    signups: 3490,
    tokens: 4300,
  },
  {
    name: 'sep',
    signups: 3490,
    tokens: 4300,
  },
  {
    name: 'oct',
    signups: 3490,
    tokens: 4300,
  },
  {
    name: 'nov',
    signups: 3490,
    tokens: 4300,
  },
  {
    name: 'dec',
    signups: 3490,
    tokens: 4300,
  },
];

export const CustomersChart = () => {

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box style={{ textAlign: 'right', marginTop: 40, height: 20 }}>
            <div style={{ width: 20, height: 20, backgroundColor: '#FFBB28', float: 'right', marginLeft: 10 }} />
            <Typography noWrap>CUSTOMERS</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ textAlign: 'left', marginTop: 40, height: 20 }}>
            <div style={{ width: 20, height: 20, backgroundColor: '#0088FE', float: 'left', marginRight: 10 }} />
            <Typography noWrap>TOKEN SOLD</Typography>
          </Box>
        </Grid>
      </Grid>
      <ResponsiveContainer width="100%" height="80%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Line type="monotone" dataKey="signups" stroke="#0088FE" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="tokens" stroke="#FFBB28" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

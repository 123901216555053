import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Formik, Form, Field } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { push } from 'connected-react-router'

import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { ActivityButton } from '../components/ActivityButton'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import LoadingButton from '@material-ui/lab/LoadingButton';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex !important",
      flexDirection: "column",
      justifyContent: "center",
      height: '100vh',
      width: '100vw !important',
      maxWidth: '100vw !important',

      backgroundImage: "url('/ico-splash.jpg')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      // paddingBottom: 40,
      height: '100%'
    },
    titleBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: 1,
    },
    paper: {
      // padding: theme.spacing(2),
      // margin: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50vw',
      borderRadius: 4,
      height: 400
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    signupGrid: {
      backgroundColor: 'red'
    }
  }),
);

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

const ForgotPasswordRequestSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

export const Home = () => {

  const dispatch = useDispatch();
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const loginError = useSelector(AuthSelectors.getLoggedError);
  let history = useHistory();
  console.log('loginError: ');
  const classes = useStyles();

  React.useEffect(() => {

    console.log('effect loginError: ', loginError);

    return () => { }

  }, [loginError])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      console.log('in onsubmit with: ', values)
      dispatch(AuthActions.willLoginUser({ email: values.email, password: values.password, history: history }));
    },
  });

  return (
    <>
      {/* <CssBaseline /> */}
      <Container className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={0} style={{ height: '100%' }}>
            <Grid item xs={6}>
              <Box className={classes.box} component="div" p={2} m={1}>
                <Box className={classes.titleBox} component="div">
                  <Box color="secondary.main"><Typography variant="h3" component="h3" align="center">manager.ICO</Typography></Box>
                </Box>
                <Box style={{ height: 220 }}>

                  <form onSubmit={formik.handleSubmit}>
                    {/* <FormGroup> */}
                    {/* <Label for="email">Email</Label> */}
                    <TextField
                      id="email"
                      label="email"
                      name="email"
                      variant="outlined"

                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      fullWidth={true}
                      style={{ marginBottom: 8 }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      placeholder="nome.cognome@gmail.com"
                    />
                    <TextField
                      id="password"
                      label="password"
                      name="password"
                      variant="outlined"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      fullWidth={true}
                      style={{ marginBottom: 8 }}
                    />
                    {/* <Input data-cy="email" invalid={errors.email && touched.email ? true : false} type="text" name="email" id="email" placeholder="insert email" autoComplete="email" tag={Field} />
                    {errors.email && touched.email ? (
                      <FormFeedback>{errors.email}</FormFeedback>
                    ) : null} */}
                    {/* </FormGroup> */}
                    {/* <FormGroup>
                    <Label for="password">Password</Label>
                    <Input data-cy="password" invalid={errors.password && touched.password ? true : false} type="password" name="password" id="password" placeholder="insert password" autoComplete="current-password" tag={Field} />
                    {errors.password && touched.password ? (
                      <FormFeedback>{errors.password}</FormFeedback>
                    ) : null}

                  </FormGroup> */}
                    {/* <ActivityButton data-cy="login" type="submit" name="login" color="primary" block>Login</ActivityButton> */}
                    <Button size="large" variant="contained" color="primary" disableElevation={true} fullWidth={true}>
                      <Link to="/dashboard" style={{textDecoration: 'none', color: 'white'}}>Login</Link>
                    </Button>
                    {/* <LoadingButton
                    pending
                    pendingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >Save</LoadingButton> */}
                    {/* <Button color="primary" block to="/signup" outline tag={Link}>Signup</Button>
            <Button color="link" block onClick={() => setForgotPassword(true)}>Forgot Password?</Button> */}
                  </form>
                </Box>

              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.box} component="div" bgcolor='secondary.main' style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                <Box className={classes.titleBox} component="div">
                  <Box color="white"><Typography variant="h3" component="h3" align="center">signup</Typography></Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  )
}
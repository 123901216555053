import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles, useTheme, Theme, createStyles, fade } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { formatWithOptions } from 'node:util';
import IconButton from '@material-ui/core/IconButton';
import AlarmIcon from '@material-ui/icons/Alarm';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';

const options = [
  { title: 'Algorand', code: "ALGORAND" },
  { title: 'Ethereum', code: "ETHEREUM" },
  { title: 'Binance', code: "BINANCE" },
  { title: 'Cardano', code: "CARDANO" },
];

const CssTextField = withStyles((theme: Theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.MuiInputBase-root': {
        color: theme.palette.secondary.main
      }
    },
    input: {
      color: theme.palette.secondary.main
    }
  },
}))(TextField);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderColor: theme.palette.secondary.dark,
      borderWidth: 6,
      borderStyle: 'solid',
      width: '80vw',
      height: '80vh',
      minHeight: '700px',
      borderRadius: 24,
      backgroundColor: 'white',
      padding: 12,
      outline: 'none'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 500
    },
    mainGrid: {
      marginTop: 28,
    },
    popupIndicator: {
      color: theme.palette.secondary.main
    },

  })
)

export const CreateTokenModal = ({ opened, setOpened }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      tokenSymbol: '',
      tokenName: '',
    },
    // validationSchema: LoginSchema,
    onSubmit: (values) => {
    },
  });

  return (
    <Modal
      open={opened}
      onClose={() => { }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ border: 0, display: "flex", alignItems: "center", justifyContent: "center", outline: 'none' }}
      onBackdropClick={() => {
        // setOpened(false);
      }}
    >

      <Box className={classes.modal}>
        <Typography variant="h5" noWrap>Create new Token</Typography>
        <Grid container spacing={5} style={{ height: '100%' }} >
          <Grid item xs={12} className={classes.mainGrid} style={{ height: 40 }}>
            <Typography variant="h6" noWrap>Token Type</Typography>
            <Autocomplete
              id="combo-box-demo"
              options={options}
              getOptionLabel={(option) => option.title}
              disableClearable={true}
              freeSolo={false}
              fullWidth={true}
              defaultValue={options[0]}
              onInputChange={(event, newInputValue) => {
              }}
              onChange={(event, newValue) => {
              }}
              classes={{
                popupIndicator: classes.popupIndicator
              }}
              renderInput={(params) => <CssTextField style={{ color: 'white' }} label="" {...params} variant="outlined" />}
            />
          </Grid>
          <Grid item xs={6} className={classes.mainGrid}>
            <Typography variant="h6" noWrap>Token Symbol</Typography>
            <TextField
              id="tokenSymbol"
              label="Token Symbol"
              name="tokenSymbol"
              variant="outlined"
              value={formik.values.tokenSymbol}
              onChange={formik.handleChange}
              error={formik.touched.tokenSymbol && Boolean(formik.errors.tokenSymbol)}
              helperText={formik.touched.tokenSymbol && formik.errors.tokenSymbol}
              fullWidth={true}
              style={{ marginBottom: 12 }}
            />
            <Typography variant="h6" noWrap>Token Name</Typography>

            <TextField
              id="tokenName"
              label="Token Name"
              name="tokenName"
              variant="outlined"

              value={formik.values.tokenName}
              onChange={formik.handleChange}
              error={formik.touched.tokenName && Boolean(formik.errors.tokenName)}
              helperText={formik.touched.tokenName && formik.errors.tokenName}
              fullWidth={true}
              style={{ marginBottom: 12 }}
            />
            <Typography variant="h6" noWrap>Total Supply</Typography>
            <TextField
              id="standard-name"
              label=""
              value="0"
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: <IconButton color="secondary" aria-label="add an alarm">
                  <RemoveIcon />
                </IconButton>,
                endAdornment: <IconButton color="secondary" aria-label="add an alarm">
                  <AddIcon />
                </IconButton>
              }}
            />
            <Typography variant="h6" noWrap>Total Decimal</Typography>
            <TextField
              id="standard-name"
              label=""
              value="0"
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: <IconButton color="secondary" aria-label="add an alarm">
                  <RemoveIcon />
                </IconButton>,
                endAdornment: <IconButton color="secondary" aria-label="add an alarm">
                  <AddIcon />
                </IconButton>
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.mainGrid}>
            <Typography variant="h6" noWrap>Options</Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">Enabling the features below, will increase the size of the token's smart-contract, slightly raising the cost of deployment.</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={false} onChange={() => { }} name="gilad" />}
                  label="Mintable"
                />
                <FormHelperText>Allow new coins to be created (minted) by the contract owner.</FormHelperText>
                <FormControlLabel
                  control={<Switch checked={true} onChange={() => { }} name="jason" />}
                  label="Burnable"
                />
                <FormHelperText>Allow some coins to be burnt (destroyed) at a later date, reducing the total supply.</FormHelperText>
                <FormControlLabel
                  control={<Switch checked={false} onChange={() => { }} name="antoine" />}
                  label="Pausable"
                />
                <FormHelperText>Allow your smart-contract to be paused, preventing transfers.</FormHelperText>
                <FormControlLabel
                  control={<Switch checked={false} onChange={() => { }} name="antoine" />}
                  label="Enable Snapshot"
                />
                <FormHelperText>Add the ability to create snapshots, which are records of balances and total supply at a given time.</FormHelperText>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'flex-end', flexGrow: 1 }}>
            <Button style={{ width: 300, marginRight: 40 }} size="large" color="secondary" onClick={() => setOpened(false)}
            >Abort</Button>
            <Button style={{ width: 300 }} size="large" color="secondary" variant="contained"
              onClick={() => setOpened(false)}
            >Create Token</Button>
          </Grid>

        </Grid>
      </Box>
    </Modal>
  )
}
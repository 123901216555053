import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, Label } from 'recharts';
import Grid from '@material-ui/core/Grid';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {

  // console.log(cx, cy, midAngle, innerRadius, outerRadius, percent, index, value);
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  // console.log('radius: ', radius)
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} style={{ fontSize: 18, fontWeight: 'bolder' }} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="mathematical">
      {`${value}`}
    </text>
  );
};


function CustomLabel(props) {
  const { cx, cy } = props.viewBox;
  return (
    <>
      <text
        x={cx}
        y={cy - 15}
        fill="rgba(0, 0, 0, 0.87)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan alignmentBaseline="middle" fontSize="12.3px" fontFamily="Roboto">
          {props.value1}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 10}
        fill="rgba(0, 0, 0, 0.54)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="31.5px" fontFamily="Roboto">
          {props.value2}
        </tspan>
      </text>
    </>
  );
}

export const TokenChart = () => (
  <ResponsiveContainer width="100%" height="100%">
    <PieChart width={400} height={400}>
      <Pie
        dataKey="value"
        data={data}
        cx={200} cy={200}
        innerRadius={130}
        outerRadius={200}
        fill="#82ca9d"
        label={renderCustomizedLabel}
        labelLine={false}
      >
        <>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label width={30} position="center"
            content={<CustomLabel value1="TOTAL" value2="1.200" />}>
          </Label>
        </>
      </Pie>
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
)
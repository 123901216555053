import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TokenChart } from '../components/TokenChart';
import { TokenLegend } from '../components/TokenLegend';
import { CustomersChart } from '../components/CustomersChart';
import { useHistory } from 'react-router-dom';
import { Header } from '../header';
import { AppDrawer } from '../drawer';
import { useStyles } from './settings.style'

export const Settings = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <AppDrawer active="Settings" />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              </Paper>
            </Grid>
          </Grid>

        </main>
      </div>
    </>
  )
}
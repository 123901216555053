import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { CssTextField } from './components/CssTextField';
import { useStyles } from './header.style'
import clsx from 'clsx';
import { CreateTokenModal } from './components/CreateTokenModal';

const icoList = [
  { title: 'ICO', code: "ICO" },
  { title: 'ALGO', code: "ALGO" },
  { title: 'BTC', code: "BTC" },
  { title: 'NINJACAT', code: "NINJACAT" },
  { title: 'RADIX', code: "RADIX" },
  { title: 'Create new Token...', code: "##ADD##" }
];

export const Header = () => {

  const classes = useStyles();
  const [coin, setCoin] = React.useState("")
  const [open, setOpen] = React.useState(true);
  const [modalOpened, setModalOpened] = React.useState(false)

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={0}
        color="secondary"
      >
        <Toolbar className={classes.toolbar}>
          <Box style={{ display: "flex", flexGrow: 1 }}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Box>
          <Box style={{ display: 'flex', width: 200, flexDirection: "column", alignItems: "flex-end", marginRight: 10 }}>
            <Typography variant="h6" noWrap>
              Asset:
  </Typography>
          </Box>
          <Autocomplete
            id="combo-box-demo"
            options={icoList}
            getOptionLabel={(option) => option.title}
            disableClearable={true}
            freeSolo={false}
            inputValue={coin}
            defaultValue={icoList[0]}
            onInputChange={(event, newInputValue) => {
              setCoin(newInputValue);
            }}
            onChange={(event, newValue) => {
              console.log('with event: ', newValue);
              if (newValue.code == "##ADD##") {
                setModalOpened(true);
              }
            }}
            classes={{
              popupIndicator: classes.popupIndicator
            }}
            style={{
              width: 300, borderColor: 'white',
            }}
            renderInput={(params) => <CssTextField style={{ color: 'white' }} label="" {...params} variant="outlined" />}
            renderOption={(option) => (
              <React.Fragment>
                {option.code == "##ADD##" ? (
                  <>
                    <b>{option.title}</b>
                  </>
                ) : (
                  <>
                    {option.title}
                  </>
                )}
              </React.Fragment>
            )
            }
          />
          <IconButton
            edge="end"
            aria-label="account of current user"
            // aria-controls={menuId}
            aria-haspopup="true"
            // onClick={handleProfileMenuOpen}
            color="inherit"
            style={{ marginLeft: 20 }}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <CreateTokenModal opened={modalOpened} setOpened={setModalOpened} />
    </>
  )
}

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const TokenLegend = () => (
  <>
    <Box style={{textAlign: 'left', marginTop: 40, height: 20}}>
      <div style={{width: 20, height: 20, backgroundColor: '#0088FE', float: 'left', marginRight: 10}} />
      <Typography noWrap>TOKEN SOLD</Typography>
    </Box>
    <Box style={{textAlign: 'left', marginTop: 40, height: 20}}>
      <div style={{width: 20, height: 20, backgroundColor: '#00C49F', float: 'left', marginRight: 10}} />
      <Typography noWrap>COINS REMAINING</Typography>
    </Box>
    <Box style={{textAlign: 'left', marginTop: 40, height: 20}}>
      <div style={{width: 20, height: 20, backgroundColor: '#FFBB28', float: 'left', marginRight: 10}} />
      <Typography noWrap>BURNED</Typography>
    </Box>
    <Box style={{textAlign: 'left', marginTop: 40, height: 20}}>
      <div style={{width: 20, height: 20, backgroundColor: '#FF8042', float: 'left', marginRight: 10}} />
      <Typography noWrap>PAUSED</Typography>
    </Box>

  </>
)
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as authReducer } from './slices/auth'
import { reducer as notificationReducer } from './slices/notification'
import { reducer as uiReducer } from './slices/ui'

export const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  notification: notificationReducer,
  ui: uiReducer,
    
})
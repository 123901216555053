import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TokenChart } from '../components/TokenChart';
import { TokenLegend } from '../components/TokenLegend';
import { CustomersChart } from '../components/CustomersChart';
import { useHistory } from 'react-router-dom';
import { Header } from '../header';
import { AppDrawer } from '../drawer';
import { useStyles } from './dashboard.style'

export const Dashboard = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <AppDrawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6" noWrap>ICO Token Status</Typography>
                <Grid container spacing={3} style={{ height: 500 }}>
                  <Grid item xs={5}>
                    <TokenLegend />
                  </Grid>
                  <Grid item xs={7}>
                    <TokenChart />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography variant="h6" noWrap>Customers</Typography>
                <CustomersChart />
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
    </>
  );
}

import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, withStyles, useTheme, Theme, createStyles, fade } from '@material-ui/core/styles';
import { useStyles } from './drawer.style'
import { Link, useHistory } from 'react-router-dom';
import { TrainRounded } from '@material-ui/icons';
import React from 'react';

export const CssListItemText = withStyles((theme: Theme) => ({
  primary: {
    color: theme.palette.secondary.main
  }
}))(ListItemText);

export const AppDrawer = ({ active = "" }) => {

  const classes = useStyles();
  let history = useHistory();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Box color="secondary.main" style={{
          marginLeft: 14,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", height: '56px'
        }}>
          <Typography paragraph style={{ fontWeight: "bolder", fontSize: 20, marginBottom: 0 }}><Link className={classes.dashboardLink} to="/dashboard">manager.ICO</Link></Typography>
        </Box>
      </div>
      <Divider />
      <List>
        {['Customers', 'Transactions', 'Settings'].map((text, index) => (
          <ListItem button key={text} onClick={() => {
            history.push(`/${text.toLowerCase()}`)
          }}>
            <React.Fragment key={text}>
              {active === text ? (
                <>
                  <ListItemIcon color="secondary" >{index % 2 === 0 ? <InboxIcon color="secondary"/> : <MailIcon color="secondary"/>}</ListItemIcon>
                  <CssListItemText color="secondary" primary={text} />
                </>
              ) : (
                <>
                  <ListItemIcon >{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                </>
              )}
            </React.Fragment>
          </ListItem>
        ))}
      </List>
    </Drawer >
  )
}
export const configuration: any = {
  dev: {
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_VjG99WG0m',

      userPoolWebClientId: '54g3o40bjnm9vkj2cdb2cssmb0',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false

    },
    'aws_appsync_graphqlEndpoint': 'https://mv5es7tnxzgdlmtcdz4jd4li4e.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  }
}


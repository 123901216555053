import { makeStyles, useTheme, Theme, createStyles, fade } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      width: '100%'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      ...theme.mixins.toolbar,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 'calc(100vh - 108px)',
      display: 'flex'
      // width: '90vw'
    },
    paperTable: {
      flexGrow: 1,
      width: '100%',
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'column',
    },
    table: {
      // minWidth: 1250,
      flexGrow: 1,
      // width: '90%',
      height: '200px',
      // heigth: '90%',
      // border: '1px solid #ff0000'

    },
    tableContainer: {
      height: '250px',
      flexGrow: 1,
      // border: '1px solid #323'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },    

  })
)
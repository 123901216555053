import React from "react";
import './App.scss';

import { Provider } from 'react-redux'
import { configureStore } from './store'
import { AppRouter } from './router';
import { NotificationLayer } from './notification';

export default function App() {
  return (
    <Provider store={configureStore({})}>
      <NotificationLayer>
        <AppRouter />
      </NotificationLayer>
    </Provider >
  );
}

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { history } from './store'
import { ConnectedRouter } from 'connected-react-router'

import { Home } from "./pages/home";
import { Dashboard } from "./pages/dashboard";

import { LoginPage } from './pages/user/login'
import { SignupPage } from './pages/user/signup'
import { SignupConfirmPage } from './pages/user/signupConfirm'
import * as AuthApi from './api/auth'
import { selectors as AuthSelectors } from './store/slices/auth'
import { Customers } from "./pages/customers";
import { Transactions } from "./pages/transactions";
import { Settings } from "./pages/settings";

const PrivateRoute = ({ children, ...rest }: any) => {

  let history = useHistory();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isChecked = useSelector(AuthSelectors.isChecked)

  return (
    <Route
      {...rest}
      render={
        props => {
          console.log("isLogged: ", isLogged)
          return (
            <>
              {isChecked ? (
                <>
                  {isLogged ? (
                    children

                  ) : (
                      <Redirect to="/login" />
                    )}
                </>
              ) : (
                  <p>waiting</p>
                )
              }

            </>
          )
        }
      }
    />
  )
}

export const AppRouter = () => {

  return (
    <ConnectedRouter history={history}>
      {/* <Container className="switchContainer" fluid> */}
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/signup/confirm/:code">
            <SignupConfirmPage />
          </Route>
          <Route path="/signup/confirm/">
            <SignupConfirmPage />
          </Route>
          <Route path="/signup">
            <SignupPage />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/customers">
            <Customers />
          </Route>
          <Route path="/transactions">
            <Transactions />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/">
            <Home />
          </Route>

        </Switch>
      {/* </Container> */}
    </ConnectedRouter>
  )
}

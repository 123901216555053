import React from 'react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles, useTheme, Theme, createStyles, fade } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { formatWithOptions } from 'node:util';
import IconButton from '@material-ui/core/IconButton';
import AlarmIcon from '@material-ui/icons/Alarm';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { DataGrid, ValueGetterParams, ValueFormatterParams, GridCellParams } from '@material-ui/data-grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';

const StyledMenuItem = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main
  }
}))(MenuItem);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderColor: theme.palette.secondary.dark,
      borderWidth: 6,
      borderStyle: 'solid',
      width: '80vw',
      height: '80vh',
      minHeight: '700px',
      borderRadius: 24,
      backgroundColor: 'white',
      padding: 12,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 700
    },
    mainGrid: {
      marginTop: 28,
    },
    popupIndicator: {
      color: theme.palette.secondary.main
    },
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      alignItems: 'stretch'
    },
    listItem: {
      padding: 0
    },
    dataGrid: {
      height: '80%',
      "& .rendering-zone": {
        width: '100% !important'

      },
      "& .MuiDataGrid-row": {
        width: '100%'
      }
    }
  })
)

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const columns: any[] = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'action', headerName: 'Action', width: 200 },
  { field: 'date', headerName: 'Date', width: 260 },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    width: 120,
    renderCell: (params: GridCellParams) => <p style={{textAlign: 'right', width: '100%'}}><strong>{params.value}</strong></p>
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 'calc(100% - 440px)',
    // valueGetter: (params: ValueGetterParams) =>
    //   `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
  },
];

const rows = [
  { id: 1, action: 'user registration', date: moment().subtract(1, 'days').subtract(10, 'hours').format('llll'), amount: 35, description: "Test" },
  { id: 2, action: 'token paid', date: moment().subtract(1, 'days').subtract(8, 'hours').format('llll'), amount: 42, description: "Test2" },
  { id: 3, action: 'token delivered', date: moment().subtract(1, 'days').subtract(5, 'hours').format('llll'), amount: 45, description: "Test3" },
  { id: 4, action: 'token accepted', date: moment().subtract(1, 'days').subtract(3, 'hours').format('llll'), amount: 16, description: "Test4" },
];

export const CustomerDetailModal = ({ opened, setOpened }) => {
  const classes = useStyles();
  const [modalOpened, setModalOpened] = React.useState(false);

  return (
    <Modal
      open={opened}
      onClose={() => { }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ border: 0, display: "flex", alignItems: "center", justifyContent: "center", outline: 'none' }}
      onBackdropClick={() => {
        setOpened(false);
      }}
    >

      <Box className={classes.modal}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <Typography style={{ marginBottom: 8 }} variant="h5" noWrap>Customer Details</Typography>
          <Typography className="MuiIconButton-colorSecondary" style={{ marginLeft: 10, flexGrow: 1 }} variant="body1" noWrap>(ACTIVE)</Typography>


          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <IconButton color="secondary" aria-label="change status" disableFocusRipple={true} disableRipple={true} {...bindTrigger(popupState)}>
                  <MoreHorizIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)} getContentAnchorEl={null} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                  <MenuItem onClick={popupState.close}>Deactivate</MenuItem>
                  <MenuItem onClick={popupState.close}>Suspend</MenuItem>
                  <StyledMenuItem onClick={popupState.close}>Remove</StyledMenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </Box>

        <Box style={{ flexGrow: 1 }}>
          <form>
            <Grid container spacing={5} style={{ height: '100%' }} >
              <Grid item xs={4} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="first_name"
                  label="First Name"
                  name="name"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder="nome.cognome@gmail.com"
                />
              </Grid>
              <Grid item xs={4} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="last_name"
                  label="Last Name"
                  name="name"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder="nome.cognome@gmail.com"
                />
              </Grid>
              <Grid item xs={4} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="birth_date"
                  label="Birthdate"
                  name="birth_date"
                  variant="outlined"
                  type="date"
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder=""
                />
              </Grid>
              <Grid item xs={3} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="address"
                  label="Address"
                  name="address"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder="nome.cognome@gmail.com"
                />
              </Grid>
              <Grid item xs={3} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="city"
                  label="City"
                  name="city"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder="nome.cognome@gmail.com"
                />
              </Grid>
              <Grid item xs={3} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="postal_code"
                  label="Postal Code"
                  name="postal_code"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder="nome.cognome@gmail.com"
                />
              </Grid>
              <Grid item xs={3} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="country"
                  label="Country"
                  name="address"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder="nome.cognome@gmail.com"
                />
              </Grid>
              <Grid item xs={6} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="tax_code"
                  label="Tax code"
                  name="city"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder=""
                />
              </Grid>
              <Grid item xs={6} className={classes.mainGrid} style={{ height: 40 }}>
                <TextField
                  id="vat_number"
                  label="VAT number"
                  name="vat_number"
                  variant="outlined"

                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  fullWidth={true}
                  style={{ marginBottom: 8 }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  placeholder=""
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box style={{ height: 100 }}>
          {/* <Divider /> */}
          <Typography style={{ marginBottom: 8 }} variant="h5" component="h5" align="left">Documents</Typography>
          <List className={classes.root}>
            <ListItem button component="a" href="https://www.google.com" className={classes.listItem} style={{ flexGrow: 1, display: 'flex' }}>
              <ListItemAvatar>
                <Avatar>
                  <PermIdentityIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Identity Front" secondary="Jan 9, 2020" />
            </ListItem>
            <ListItem button component="a" href="https://www.google.com" className={classes.listItem} style={{ flexGrow: 1, display: 'flex' }}>
              <ListItemAvatar>
                <Avatar>
                  <PermIdentityIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Identity Rear" secondary="Jan 9, 2020" />
            </ListItem>
            <ListItem button component="a" href="https://www.google.com" className={classes.listItem} style={{ flexGrow: 1, display: 'flex' }}>
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Bank" secondary="Jan 7, 2020" />
            </ListItem>
          </List>
        </Box>
        <Box style={{ flexGrow: 4 }}>
          <Typography style={{ marginBottom: 20 }} variant="h5" component="h5" align="left">History</Typography>
          <DataGrid disableExtendRowFullWidth={false} checkboxSelection={false} rows={rows} columns={columns} pageSize={5} className={classes.dataGrid} />
        </Box>
      </Box>
    </Modal>
  )
}